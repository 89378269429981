<template>
  <div class="login-page container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-xl-3 mx-auto login-container">
        <img src="@/assets/logo.png" alt="Expertel IQ" class="img-fluid logo" />
        <transition name="slide-fade">
          <form @submit.prevent="resetPassword()">
            <h5 class="mb-4">Please set your new password</h5>
            <input
              v-model="password"
              type="password"
              placeholder="Password"
              class="form-control"
            />
            <input
              v-model="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              class="form-control"
            />
            <ul class="row my-4 pl-0 text-left list-password">
              <li
                class="col-12 col-sm-12 col-md-6"
                :class="hasLowerCase == true ? 'correct' : ''"
              >
                One lowercase character
              </li>
              <li
                class="col-12 col-sm-12 col-md-6"
                :class="hasUpperCase == true ? 'correct' : ''"
              >
                One uppercase character
              </li>
              <li
                class="col-12 col-sm-12 col-md-6"
                :class="hasNumber == true ? 'correct' : ''"
              >
                One number
              </li>
              <li
                class="col-12 col-sm-12 col-md-6"
                :class="leastEightNumbers == true ? 'correct' : ''"
              >
                8 characters minimum
              </li>
            </ul>
            <button type="submit" class="button-expertel text-center">
              CHANGE PASSWORD
            </button>
          </form>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      password: "",
      confirmPassword: "",
      resetToken: "",
      hasNumber: false,
      hasLowerCase: false,
      hasUpperCase: false,
      leastEightNumbers: false
    };
  },
  watch: {
    password: function(newValue) {
      let upperCaseTester = /[A-Z]/;
      let lowerCaseTester = /[a-z]/;
      let numberTester = /\d/;

      if (upperCaseTester.test(newValue)) {
        this.hasUpperCase = true;
      } else {
        this.hasUpperCase = false;
      }

      if (lowerCaseTester.test(newValue)) {
        this.hasLowerCase = true;
      } else {
        this.hasLowerCase = false;
      }

      if (numberTester.test(newValue)) {
        this.hasNumber = true;
      } else {
        this.hasNumber = false;
      }

      if (newValue.length >= 8) {
        this.leastEightNumbers = true;
      } else {
        this.leastEightNumbers = false;
      }
    }
  },
  created() {
    if (
      this.$route.params.token !== undefined ||
      this.$route.params.token !== ""
    ) {
      this.resetToken = this.$route.params.token;
    }
  },
  methods: {
    resetPassword() {
      let self = this;
      if (
        this.hasNumber === false ||
        this.hasLowerCase === false ||
        this.hasUpperCase === false ||
        this.leastEightNumbers === false
      ) {
        this.makeToast(
          "The password does not contain the minimum security requirements. ",
          "danger"
        );
        return false;
      }
      if (this.password == this.confirmPassword) {
        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/reset_token/${this.resetToken}`,
          data: { password: this.password },
          responseType: "json"
        })
          .then(function(response) {
            if (response.data.success == false) {
              self.makeToast("Invalid reset Token.", "danger");
            } else {
              self.$router.push("/");
            }
          })
          .catch(error => this.makeToast("ERROR", error, "danger"));
      } else {
        this.makeToast("Passwords don't match.", "danger");
      }
    },
    makeToast(message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: "Error",
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.list-password {
  list-style: none;
  li {
    position: relative;
    padding: 0.5rem 0 0.5rem 1rem;
    opacity: 0.5;
    transition: all 0.5s;
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.5);
      top: calc(50% - 3px);
      left: 0.3rem;
      border-radius: 30px;
    }
    &.correct {
      color: #ff502d;
      opacity: 1;
      &::before {
        background-color: #ff502d;
      }
    }
  }
}
</style>
